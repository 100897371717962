$(document).ready(function () {
	var isShow = false;
	if (categoryParentId == window.categoryRootId)
	{
		isShow = true;
	}
	var filterModal = new Filter({
		filterId: 'filterContainer',
		categoriesId: 'categories',
		name: categoryName,
		seoname: categorySeoName,
		catId: categoryId,
		showFilter: isShow
	});
	filterModal.show();
});


/**
 * Filter plugin: load categories by filter
 * dependency: jquery, common.js
 */
(function (root, factory) {
	root['Filter'] = factory();
}(this, function () {

	var defaultImage = LOC_RESOURCE + SITE_ID + aliasPath + '/site_4/images/default_eb.png';

	function containerFilter(config)
	{

		this.filterTemplate =
				'<div class="filter__title"><span class="filter__title-text">' + NEU.util.getLocalizedString("filter_label") + '</span></div>' +
				'<div id="filter" class="filter js-filter">' +
				'<div class="btn filter__btn is-current">' +
				'<span class="filter__btn-text"></span>' +
				'<i class="filter__icon-down"></i>' +
				'</div>' +
				'<div id="filter-wrapper" class="scroll-wrapper">' +
				'<ul  class="filter__list js-filter__list">' +
				'</ul>' +
				'</div>' +
				'</div>';
		this.genresItemTemplate = '<li class="filter__item {li_selected}"><a href="javascript:void(0)" class="filter__item-link" data-type="filter" data-seoname="{genre_seoname}" data-name="{genre_name}">{genre_name}</a></li>';

		this.programItemTemplate =
				'<div class="program program--center col-xs-12 col-sm-6 col-md-4 col-lg-3">' +
				'<a href="{program_link}" class="program__img-link program__img-wrap js-img-link">' +
				'<img class="img-responsive program__img" src="{program_img}" title="{program_name}" alt="{program_name}" onerror="this.src=\'' + defaultImage + '\';this.error=null;">' +
				'{program_tag}' +
				'</a>' +
				'</div>';
		this.noContent = '<div>' +
				'<div class="no-content"><div>' + NEU.util.getLocalizedString("categories_no_result") + '</div><div></div>' +
				'</div>' +
				'</div>';


		var defaultConfig = {
			ps: window.categoryPageSize,
		};

		this.options = $.extend(true, {}, defaultConfig, config);

		var owner = this;
		this.startNum = 1;
		this.currentId= owner.options.catId;
		this.currentName = owner.options.name;
		this.currentSeoName = owner.options.seoname;
		this.isFirstLoad = true;
		this.canLoadMore = true;
		this.isRenderOver = true;

		// Init
		this.show = function () {
			this.domId = this.options.filterId;
			this.resultId = this.options.categoriesId;
			this.loadMoreHandle();
			if (owner.options.showFilter)
			{
				this.renderFilterContainer();
			}
			else
			{
				this.renderCategories();
			}
		}

		// Show filter container with no list
		this.renderFilterContainer = function () {
			var container = document.getElementById(this.domId);
			container.innerHTML = this.filterTemplate;
			$(".js-filter").click(function () {
				$(this).toggleClass("is-open");
			});
			this.getGenresListForFilter();
			this.filterSelectHandle();
		}

		// Get Genres list data
		this.getGenresListForFilter = function () {
			window.getGenres(function (data) {
				var html = '';
				var genresObj = data.response.docs;

				for (var i in  genresObj)
				{
					var selectedstr = '';
					if (owner.options.seoname.toLocaleLowerCase() == genresObj[i].seoName.toLocaleLowerCase())
					{// get initial data
						selectedstr = " is-selected ";
					}
					var genresItem = genresObj[i];
					html += owner.genresItemTemplate.replace(/\{li_selected\}/gi, selectedstr)
							.replace(/\{genre_name\}/gi, genresItem.name)
							.replace(/\{genre_seoname\}/gi, genresItem.seoName);
				}
				$('.js-filter__list').html(html);
				owner.getCategoriesResult();
				owner.setScroll();//iScroll
			});
		}

		this.setScroll = function () {
			new IScroll('#filter-wrapper', {
				id: 'wrapper1',
				interactiveScrollbars: true,
				mouseWheel: true,
				scrollbars: 'custom',
				tap: true
			});
		}

		// Get categories result
		this.getCategoriesResult = function () {
			$('#filter').find('span').html(owner.currentName);
			$('.head-title').html(owner.currentName);
			owner.renderCategories();
		}

		// Render categories result
		this.renderCategories = function () {
			if (!owner.canLoadMore || !owner.isRenderOver)
			{
				return false;
			}
			owner.isRenderOver = false;
			this.loadingShowHandle(true);
			var catId = owner.currentId;
			var pageNum = parseInt(owner.startNum / owner.options.ps);
			getShowsByParentCatId(catId, pageNum, owner.options.ps, function (data) {
				var html = "";
				checkCanLoadMore(data.response);
				var categoriesObj = data.response.docs;
				if (categoriesObj.length == 0)
				{
					html = owner.noContent;
				}
				else
				{
					for (var i in categoriesObj)
					{
						var program = categoriesObj[i];
						var program_link = LOC_SERVER + "category/" + program.seoName;
						var program_img = LOC_CONTENT + 'categories/' + program.catId + '_eb.jpg';
						var program_tag = program.style ? '<img class="category__tag tag-img-eb hidden-xs" src="' + LOC_RESOURCE + SITE_ID + '/site_4/images/' + program.style.toLowerCase() + '_eb.png">'+'<img class="category__tag tag-img-eb hidden-sm hidden-md hidden-lg" src="' + LOC_RESOURCE + SITE_ID + '/site_4/images/' + program.style.toLowerCase() + '_bg.png">' : '';
						html += owner.programItemTemplate.replace('{program_img}', program_img)
								.replace('{program_link}', program_link)
								.replace(/\{program_name\}/gi, program.name)
								.replace(/\{program_description\}/gi, program.description)
								.replace(/\{program_tag\}/gi,program_tag);
					}
				}
				// Load more button
				owner.loadMoreShowHandle();
				// Loading
				owner.loadingShowHandle(false);

				if (owner.isFirstLoad)
				{
					$('#' + owner.resultId).html(html);
					owner.isFirstLoad = false;
				}
				else
				{
					$('#' + owner.resultId).append(html);
				}

				owner.isRenderOver = true;

			})
		}

		// Check can load more or not
		function checkCanLoadMore(responseObj)
		{
			var resultNum = responseObj.numFound;
			owner.startNum = responseObj.start + responseObj.docs.length;
			if (owner.startNum < resultNum)
			{
				owner.canLoadMore = true;
			}
			else
			{
				owner.canLoadMore = false;
			}
		}

		// Check the load more button  show or hide
		this.loadMoreShowHandle = function () {
			if (owner.canLoadMore)
			{
				$('.more').show();
			}
			else
			{
				$('.more').hide();
			}
		}

		// Add click event for load more button
		this.loadMoreHandle = function () {
			// Click button to load more
			$('.load-more').click(function () {
				owner.renderCategories();
			});

			// Scroll to load more
			$(window).scroll(function () {
				var $categoriesContainer = $('#' + owner.options.categoriesId);
				var scrollTop = $(window).scrollTop();
				var domTop = $categoriesContainer.offset().top;
				var domHeight = $categoriesContainer.height();
				var windowHeight = $(window).height();
				var domTopHeight = domTop + domHeight + 200;
				if (scrollTop + windowHeight >= domTopHeight)
				{
					if (!owner.isFirstLoad)
					{
						owner.renderCategories();
					}
				}
			});

		}

		// Check loading icon show or hide
		this.loadingShowHandle = function (isShow) {
			$('.loading').toggle(isShow);
		}

		// Add click event for li
		this.filterSelectHandle = function () {
			$('.js-filter__list').on('tap', 'li', function () {
				owner.startNum = 0;
				$('li').removeClass('is-selected');
				$(this).addClass('is-selected');

				owner.currentName = $(this).find('a').attr('data-name');
				owner.currentSeoName = $(this).find('a').attr('data-seoname');
				owner.isFirstLoad = true;
				window.location.href = window.LOC_SERVER + 'category/' + owner.currentSeoName;
			});
		}

	}

	return containerFilter;
}));





